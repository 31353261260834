
toggleAll(".js-toggle");


function toggleAll(anchorClass) {
	var regOpen = / ?open/;

	[].forEach.call(document.querySelectorAll(anchorClass), function (el) {
		el.toggleTarget = document.querySelectorAll(el.dataset.toggleTarget);
		el.addEventListener('click', toggle);
	});

	function toggle(event) {
		event.preventDefault();

		var el = this,
			target = el.toggleTarget,
			bodyEvent;

		[].forEach.call(target, function (el) {
			if (regOpen.test(el.className)) {
				el.className = el.className.replace(regOpen, "");
			} else {
				el.className = el.className + " open";
			}
		});
	}
}

